export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Holiday",
    key: "holiday_occasion",
    minWidth: 80,
  },
  {
    title: "Date",
    key: "date",
    minWidth: 80,
    sortable: true,
  },
  {
    title: "Day",
    key: "Day",
    minWidth: 80,
  },
];
