<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Holiday List" slot="title" link="/helpContent/holidayList" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-2">
            <Year label="Year" v-model="filtering.year" />
          </div>
          <div class="col-sm-2">
              <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="holidayList"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      @on-page-change="pageChange"
      @on-sort-change="sortChange">
      <!-- :pagination="pagination" if you want pagination please add this line in datatable tag -->
      <!--sagar 12-09-2023 button show only id=46 HR Role not Staff  -->
      <template slot="actionsLeft" v-if="userData.roles.indexOf(46)!==-1">
        
        <TableButton @click="() => add()">
          <img :src="require(`Assets/images/plus-1.png`)"  />
          Add
        </TableButton>
        <TableButton @click="() => remove()">
          <img :src="require(`Assets/images/del.png`)"  />
          Remove
        </TableButton>
      </template>
    </DataTable>
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmDelete"
          >Confirm
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.ok"
          @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import DataTable from "Components/DataTable";
  import Year from "../../../components/Year";
  import loadingMixin from "Mixins/loadingMixin";
  import { holidayList,removeHoliday } from "../api";
  import tableConfig from "./table";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { ValidationObserver } from "vee-validate";
  export default {
    name: "List",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
      FormWrapper,
      DataTable,
      PageTitle,
      ValidationObserver,
      Year,
    },
    data() {
      return {
        filtering: {
          year: new Date().getFullYear(),
        },
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        tableConfig,
        holidayList: [],
        selectedRows: [],
        showMessage: {
          isVisible: false,
          message: "",
        },
        buttonToShow: "",
      };
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    created() {
      this.getDataByFilter();
    },
    methods: {
      add() {
        this.$router.push({ path: "/addHoliday" });
      },
      search() {
        this.pagination.page = 1;
        this.getDataByFilter();
      },
      selectChange(data) {
        this.selectedRows = data;
      },
      sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
      remove() {
        if (this.validateSelectedLength(1)) {
          if (this.deleteSelectedValue()) {
          }
        }
      },
      confirmDelete() {
        this.showWarningModal(false);
        const data = {
          date: this.selectedRows[0].date,
        };
        removeHoliday(data).then((res) => {
          if (res.data[0].status > 1) {
            this.showWarningModal(true);
            this.selectedRows=[];
            this.warningModal.buttons.ok =true;
            this.warningModal.buttons.cancel =false;
            this.warningModal.buttons.confirm =false;
            this.warningModal.message = res.data[0].message;
          } else {
            this.selectedRows=[];
            this.warningModal.buttons.ok =true;
            this.warningModal.buttons.cancel =false;
            this.warningModal.buttons.confirm =false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
            this.getDataByFilter();
          }
        });
      },
      getDataByFilter() {
        setTimeout(() => {
              this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;

              const filters = {};
              filters.year = this.filtering.year;

              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startindex: page,
                      pagesize: perPage,
                    },
                  ],
                },
              };
              holidayList(data)
                .then(this.handleResponse)
                .catch(this.handleError);
        }, 50);
      },
      pageChange(page) {
        this.pagination.page = page;
        this.getDataByFilter();
      },
      handleResponse(response) {
        this.hideLoader();
        this.holidayList = response.data;
        this.buttonToShow = this.filtering.year;
      },
    },
  };
</script>